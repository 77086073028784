<template>
  <div class="shuili">
    <div class="change">
      <div class="son" v-for="(item, index) in imgs" :key="index">
        <img :src="item.src" alt="" :style="item.style" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {
          src: require("../../../assets/imgs/guanyu/zizi/17.png"),
          name: "重庆市科技进步二等奖",
          style: "width: 122px",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/18.png"),
          name: "成都市科技进步二等奖",
          style: "width: 122px",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/1.png"),
          name: "电子与智能化工程专业承包壹级",
          style: "width: 122px",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/2.png"),
          name: "安防工程企业设计施工维护能力证书（壹级）",
          style: "width: 122px",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/3.png"),
          name: "”守合同重信用“企业公式证明",
          style: "width: 122px",
        },

        {
          src: require("../../../assets/imgs/guanyu/zizi/4.png"),
          name: "企业信用等级证书（3A）",
          style: "width: 122px",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/5.png"),
          name: "测绘资质证",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/6.png"),
          name: "CMMI证书",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/7.png"),
          name: "信息技术服务运行维护标准（贰级）",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/8.png"),
          name: "水文、水资源调查评价资质证书",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/9.png"),
          name: "软件企业证书",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/10.png"),
          name: "企业信用等级证书（3A）",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/11.png"),
          name: "高新技术企业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/12.png"),
          name: "四川省企业技术中心",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/13.png"),
          name: "成都市企业技术中心",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/14.png"),
          name: "2016中国应急管理信息化产品技术创新奖",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/15.png"),
          name: "中国应急管理信息化方案案例创新奖",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/16.png"),
          name: "2015中国应急管理信息化优秀方案奖",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.shuili {
  width: 100%;
  .change {
    width: 1400px;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .son {
      width: 240px;
      /// height: 206px;

      background: rgba(246, 246, 246, 1);
      padding: 8px 12px;
      margin-top: 46px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 40px;
      img {
        width: 216px;
        height: 150px;
      }
      .name {
        margin-top: 6px;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 16px;
        font-family: Alibaba PuHuiTi Xi;
        font-weight: 400;
        line-height: 30px;
        color: #333333;
        text-align: center;
        opacity: 1;
      }
    }
  }
}
</style>