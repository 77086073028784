<template>
  <div class="shuili">
    <div class="change">
      <div class="son" v-for="(item, index) in imgs" :key="index">
        <img :src="item.src" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {
          src: require("../../../assets/imgs/guanyu/zhuzuo/1.jpg"),
          name: "汉康城市运营管理中心软件",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuzuo/2.jpg"),
          name: "汉康政务信息资源门户应用软件",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuzuo/3.jpg"),
          name: "汉康云计算操作系统软件",
        },

        {
          src: require("../../../assets/imgs/guanyu/zhuzuo/4.jpg"),
          name: "汉康平安城市监控系统",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuzuo/5.jpg"),
          name: "汉康公共管理与应急指挥系统",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuzuo/6.jpg"),
          name: "汉康智慧城市数据管理平台",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuzuo/7.jpg"),
          name: "汉康智慧城市决策支持与实时监测系统",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuzuo/8.jpg"),
          name: "汉康大数据分析及应用管理系统软件",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuzuo/9.jpg"),
          name: "汉康地质灾害防治预警与决策系统软件",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.shuili {
  width: 100%;
  .change {
    width: 1400px;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .son {
      width: 232px;
      //height: 278px;

      background: rgba(246, 246, 246, 1);
      padding: 8px 12px;
      margin-top: 46px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 20px;
      img {
        width: 166px;
        height: 220px;
      }
      .name {
        margin-top: 6px;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 16px;
        font-family: Alibaba PuHuiTi Xi;

        font-weight: 400;
        line-height: 30px;
        color: #333333;
        text-align: center;
        opacity: 1;
      }
    }
  }
}
</style>