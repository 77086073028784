<template>
  <div class="shuili">
    <div class="change">
      <div class="son" v-for="(item, index) in imgs" :key="index">
        <img :src="item.src" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/1.png"),
          name: "一种地质灾害监测系统及方法",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/2.png"),
          name: "地质灾害监测设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/3.png"),
          name: "碳排放强度监测传感器安装设备",
        },

        {
          src: require("../../../assets/imgs/guanyu/zhuanli/4.png"),
          name: "设计合理便于使用的食品安全质量检测设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/5.png"),
          name: "检测准确率较高食品安全检测设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/6.png"),
          name: "节能环保的食品安全检测设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/7.png"),
          name: "食品安全质量检测设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/8.png"),
          name: "山体滑坡监测站设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/9.png"),
          name: "水位预警系统监测站支撑设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/10.png"),
          name: "空气质量监测传感器精度保障设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/11.png"),
          name: "节能环保的地质灾害监测设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/12.png"),
          name: "防干扰的物流园区信息管理装置",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/13.png"),
          name: "泥石流灾害监测设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/14.png"),
          name: "碳排放强度监测传感器安装设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/15.png"),
          name: "山体滑坡灾害监测设备",
        },
        {
          src: require("../../../assets/imgs/guanyu/zhuanli/16.png"),
          name: "一种城市数据采集终端",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.shuili {
  width: 100%;
  .change {
    width: 1400px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .son {
      width: 232px;
      // height: 278px;

      background: rgba(246, 246, 246, 1);
      padding: 8px 12px;
      margin-top: 46px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 166px;
        height: 220px;
      }
      .name {
        margin-top: 6px;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 16px;
        font-family: Alibaba PuHuiTi Xi;

        font-weight: 400;
        line-height: 30px;
        color: #333333;
        text-align: center;
        opacity: 1;
      }
    }
  }
}
</style>