<template>
  <div class="content">
    <!-- <div class="title">
      发展历程
      <em></em>
    </div> -->
    <div class="title">发展历程</div>
    <div class="box"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.content {
  width: 1920px;
  height: 802px;
  margin: 0 auto;
  padding: 0px 260px;

  position: relative;

  background: rgba(36, 41, 51, 1);

  .title {
    width: 100%;
    font-size: 36px;
    font-family: Alibaba PuHuiTi;
    font-weight: normal;

    color: #ffffff;
    text-align: center;
    position: relative;
    top: 60px;
  }
  .box {
    width: 1400px;
    height: 616px;
    background: url("../../../assets/imgs/guanyu/fzbg.png");
    background-size: 100% 100%;
    position: relative;
    top: 80px;
  }
}
</style>