<template>
  <div class="index">
    <!-- <div v-if="bool" ref="header">
      <Header></Header>
    </div> -->
    <div ref="header"><Header :bg="bg"></Header></div>

    <div class="toubu">
      <!-- <div class="text">
        关于汉康
        <div class="bottom">About hankang</div>
      </div> -->
      <div class="title">中国领先的智慧城市解决方案与技术服务提供商</div>
      <div class="video">
        <div class="bofang" v-show="bofang" @click="playvideo"></div>
        <video
          :controls="!bofang"
          src="../../assets/video/guanwang.mp4"
          ref="video"
        ></video>

        <div class="xuanchuan">
          <div class="em"></div>
          成都汉康信息产业企业宣传片
          <div class="em"></div>
        </div>
      </div>

      <div class="shubiao" ref="shubiao"></div>
      <div class="lis">
        <div class="dian"></div>
        <div class="dian"></div>
        <div class="dian"></div>
      </div>
    </div>
    <!-- <div class="nav" ref="nav">
      <div
        class="son"
        v-for="(item, index) in list"
        :key="index"
        @mouseover="item.val = false"
        @mouseout="item.val = true"
        @click="active(item)"
        :class="item.type == type ? 'active' : ''"
      >
        {{ item.title }}
        <em v-show="item.type == type"></em>
      </div>
    </div> -->

    <Synopsis></Synopsis>

    <!-- <Culture></Culture> -->

    <Develop></Develop>

    <Certification></Certification>

    <!-- <Join></Join> -->

    <!-- <Relation></Relation> -->

    <Footer></Footer>

    <div class="toback" ref="toback">
      <div class="toback-lianxi">
        <div class="toback-ph">
          <div class="toback-sons">
            <div class="toback-barss">
              <div class="toback-top">咨询电话</div>
              <div class="toback-bottom">133-3098-1239</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">技术支持</div>
              <div class="toback-bottom">189-8005-3442</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">售后电话</div>
              <div class="toback-bottom">400-8878-352</div>
            </div>
          </div>
        </div>
        <div class="toback-wx">
          <div class="toback-son">
            <img src="../../assets/imgs/home/gongzonghao.png" alt />
            <div class="toback-tip">(成都汉康微信)</div>
          </div>
        </div>
      </div>
      <div class="toback-up" @click="up"></div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Synopsis from "./components/Synopsis.vue";
import Footer from "../../components/Footer.vue";
//import Culture from "./components/Culture.vue";
import Develop from "./components/Develop.vue";
import Certification from "./components/Certification.vue";
//import Join from "./components/Join.vue";
//import Relation from "./components/Relation.vue";
export default {
  components: {
    Header,
    Synopsis,
    Footer,
    //Culture,
    Develop,
    Certification,
    // Join,
    //Relation,
  },
  data() {
    return {
      type: 1,
      list: [
        {
          val: true,
          src: require("../../assets/imgs/home/smoll (1).png"),
          active: require("../../assets/imgs/home/big (1).png"),
          title: "公司简介",
          type: 1,
          name: "yi",
        },
        {
          val: true,
          src: require("../../assets/imgs/home/smoll (4).png"),
          active: require("../../assets/imgs/home/big (4).png"),
          title: "企业文化",
          type: 2,
          name: "er",
        },
        {
          val: true,
          src: require("../../assets/imgs/home/smoll (2).png"),
          active: require("../../assets/imgs/home/big (2).png"),
          title: "发展历程",
          type: 3,
          name: "san",
        },
        {
          val: true,
          src: require("../../assets/imgs/home/smoll (5).png"),
          active: require("../../assets/imgs/home/big (5).png"),
          title: "加入汉康",
          type: 4,
          name: "si",
        },
        {
          val: true,
          src: require("../../assets/imgs/home/smoll (3).png"),
          active: require("../../assets/imgs/home/big (3).png"),
          title: "联系我们",
          type: 5,
          name: "wu",
        },
      ],
      time: null,
      bool: true,
      timer: null,
      arr: [],
      bg: null,
      bofang: true,
    };
  },
  methods: {
    active(item) {
      this.type = item.type;
    },
    up() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let sudu = 40;
      if (top >= 0 && top < 1000) {
        sudu = 40;
      } else if (top >= 1000 && top < 2000) {
        sudu = 60;
      } else if (top >= 2000 && top < 3000) {
        sudu = 100;
      } else if (top >= 3000 && top < 4000) {
        sudu = 140;
      } else {
        sudu = 200;
      }
      this.timer = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            sudu;

        if (top <= 0) {
          clearInterval(this.timer);
        }
      }, 10);

      // document.body.scrollTop = 0;
    },
    scroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.arr.push(scroll);
      //  console.log("scroll :>> ", scroll);
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");
          //控制导航条

          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";

          if (scroll >= 470) {
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          // console.log("往上滑");
          if (scroll > 300) {
            this.$refs.header.style.position = "fixed";
            this.$refs.header.style.top = "0";
            this.$refs.header.style.left = "0";
            this.$refs.header.style.zIndex = "100";
            this.bg = "black";
          }
        }
        if (scroll > 120) {
          this.$refs.toback.style.opacity = "1";
        } else {
          this.$refs.toback.style.opacity = "0";
        }
      }
      if (scroll <= 280) {
        this.$refs.header.style.position = "absolute";
        this.$refs.header.style.top = "0";
        this.$refs.header.style.left = "0";
        this.$refs.header.style.zIndex = "100";
      }
    },
    playvideo() {
      this.$refs.video.play();
      // console.log(
      //   "   this.$refs.video.readyState; :>> ",
      //   this.$refs.video.readyState
      // );
      this.bofang = false;
    },
  },

  created() {},
  watch: {},
  mounted() {
    document.body.scrollTop = 0;

    window.addEventListener("scroll", this.scroll, true);
    // console.log("this.refs :>> ", this.$refs.video.seeking);
    // this.bofang = this.$refs.video.seeking;
  },
  beforeDestroy() {
    // this.time=null
    window.removeEventListener("scroll", this.scroll, true);
  },
};
</script>

<style lang="scss" scoped>
.active {
  font-weight: 700 !important;
  color: rgba(68, 112, 255, 1) !important;
}

.index {
  width: 1920px;
  height: 100%;
  background-color: #fff;
  margin: 0 auto;

  position: relative;

  .toubu {
    width: 1920px;
    height: 980px;
    background-image: url("../../assets/imgs/guanyu/top.png");
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
    //  margin-bottom: 80px;
    // .text {
    //   width: 462px;
    //   position: absolute;
    //   top: 180px;
    //   left: 260px;
    //   font-size: 30px;
    //   font-weight: 700;
    //   border-bottom: 1px solid rgba(75, 75, 75, 1);
    //   padding-bottom: 20px;
    //   .bottom {
    //     font-weight: 300;
    //   }
    // }
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      position: absolute;
      top: 136px;
      left: 0;
      width: 100%;
      font-size: 36px;
      font-family: Alibaba PuHuiTi;
      font-weight: normal;
      line-height: 50px;
      color: #c1c8f4;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .video {
      width: 818px;
      height: 460px;
      //background: rgba(67, 67, 67, 0.41);
      // filter: blur(4px);
      // display: flex;
      // align-items: center;
      // justify-content: center;
      position: relative;
      .bofang {
        width: 68px;
        height: 68px;
        background: url("../../assets/imgs/guanyu/bofang.png");
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        z-index: 100;
      }
      video {
        width: 100%;
        height: 100%;
      }
      .xuanchuan {
        margin-top: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: Alibaba PuHuiTi Zx;
        font-weight: normal;
        line-height: 17px;
        color: #9e9e9e;
        .em {
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #9e9e9e;
          margin: 0 5px;
        }
      }
    }

    .shubiao {
      width: 24px;
      height: 38px;
      background-image: url("../../assets/imgs/home/shubiao.png");
      background-size: 100% 100%;
      position: absolute;
      bottom: 80px;
      right: 948px;
      animation: run 2s linear infinite;
    }
    .lis {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .dian {
        width: 4px;
        height: 4px;
        background: rgba(255, 255, 255, 1);
        border-radius: 50%;
        margin-top: 10px;
      }
    }
  }
  .nav {
    // margin-top: 80px !important;
    margin-bottom: 80px !important;
    width: 100%;
    display: flex;
    margin: 0 auto;
    // border-bottom: 2px solid rgba(235, 235, 235, 1);
    top: 0;
    left: 0px;
    background-color: #f5f8ff;
    padding: 0 260px;
    color: rgba(162, 162, 162, 1);
    font-size: 18px;
    height: 84px;
    .son {
      width: 220px;

      position: relative;
      cursor: pointer;
      display: flex;

      // justify-content: center;
      align-items: center;
      em {
        position: absolute;
        width: 40px;
        height: 2px;
        background-color: rgba(68, 112, 255, 1);
        bottom: -2px;
        left: 0;
      }
      .img {
        width: 100%;
        height: 64px;
        display: flex;
        flex-direction: column-reverse;
        img {
          width: 48px;
          height: 48px;
        }
      }
      .title {
        font-size: 18px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        margin-top: 16px;
        color: #a2a2a2;
        opacity: 1;
      }
      // .test {
      //   width: 64px;
      //   height: 64px;
      // }
    }
    .son:hover .title {
      color: rgba(68, 112, 255, 1);
      font-weight: 700;
    }
    .son:hover .img img {
      width: 64px;
      height: 64px;
    }
  }
}

@keyframes run {
  0% {
    bottom: 80px;
  }
  50% {
    bottom: 90px;
  }
  100% {
    bottom: 80px;
  }
}
</style>