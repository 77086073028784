<template>
  <div class="content">
    <div class="title">荣誉资质</div>
    <div class="text">
      公司在智慧水务、城市安全、地理信息、智慧城市等多个领域拥有各类软硬件20多套，拥有自主知识产权专利著作权100余项，形成科技成果30余项，水文地质及桥隧工程监测预警信息平台关键技术及应用获得重庆市科技进步二等奖，地质灾害监测系统技术获成都市科技进步二等奖；汉康已是本行业资质最全最高的企业之一。
    </div>
    <div class="nav">
      <div
        class="son"
        v-for="(item, index) in list"
        :key="index"
        @click="active(item)"
        :class="item.type == type ? 'active' : ''"
      >
        {{ item.name }}
        <em></em>
      </div>
    </div>
    <Zizi v-if="type == 1"></Zizi>
    <Zhuzuo v-if="type == 2"></Zhuzuo>
    <Zhuanli v-if="type == 3"></Zhuanli>
  </div>
</template>

<script>
import Zizi from "./zizi.vue";
import Zhuanli from "./zhuanli.vue";
import Zhuzuo from "./zhuzuo.vue";
export default {
  components: {
    Zizi,
    Zhuanli,
    Zhuzuo,
  },
  data() {
    return {
      type: 1,
      list: [
        { name: "资质篇", type: 1 },
        { name: "著作权篇", type: 2 },
        { name: "专利篇", type: 3 },
      ],
      imgs: [
        {
          src: require("../../../assets/imgs/guanyu/zizi/1.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/2.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/3.png"),
          name: "国家高新技术产业",
        },

        {
          src: require("../../../assets/imgs/guanyu/zizi/4.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/5.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/6.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/7.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/8.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/9.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/10.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/11.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/12.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/13.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/14.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/15.png"),
          name: "国家高新技术产业",
        },
        {
          src: require("../../../assets/imgs/guanyu/zizi/16.png"),
          name: "国家高新技术产业",
        },
      ],
    };
  },

  methods: {
    active(item) {
      this.type = item.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: rgba(68, 112, 255, 1) !important;
  font-weight: 700 !important;
  em {
    display: block !important;
  }
}
.content {
  width: 1920px;
  // height: 700px;
  margin: 0 auto;
  padding: 0px 260px;
  margin-bottom: 100px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;

    color: #333333;
    opacity: 1;
    margin-top: 40px;
  }
  .text {
    width: 1400px;
    margin-top: 20px;
    font-size: 14px;
    font-family: Alibaba PuHuiTi Xi !important;
    font-weight: normal;
    line-height: 30px;
    color: #666666;

    opacity: 1;
  }
  .nav {
    display: flex;
    width: 1400px;
    height: 50px;
    border-bottom: 1px solid rgba(235, 235, 235, 1);
    margin-top: 30px;
    .son {
      font-size: 18px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      width: 100px;
      color: #a2a2a2;
      opacity: 1;
      display: flex;
      align-items: center;
      margin-right: 80px;
      position: relative;
      cursor: pointer;
      em {
        width: 30px;
        height: 2px;
        background-color: rgba(68, 112, 255, 1);
        position: absolute;
        bottom: -2px;
        left: 0;
        display: none;
      }
    }
    .son:hover {
      color: rgba(68, 112, 255, 1);
      font-weight: 700;
    }
    .son:hover em {
      display: block;
    }
  }
  .change {
    width: 1400px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .son {
      width: 240px;
      height: 206px;

      background: rgba(246, 246, 246, 1);
      padding: 8px 12px;
      margin-top: 46px;

      img {
        width: 216px;
        height: 150px;
      }
      .name {
        margin-top: 6px;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 16px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        line-height: 30px;
        color: #333333;

        opacity: 1;
      }
    }
  }
}
</style>