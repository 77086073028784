<template>
  <div class="content">
    <div class="jianjie">
      <div class="title">成都汉康信息产业有限公司简介</div>
      <div class="text">
        成都汉康信息产业有限公司创立于2001年，是一家致力于新型智慧城市建设服务的高新技术企业。专注于智慧水务、智慧环保、智慧政务、智慧交通、地质灾害、软件开发及物联网等领域业务，业务范围涵盖前期咨询、方案设计、软件开发、系统集成和应用服务等各环节。
        <br />
        <br />

        成都汉康多年来在智慧城市建设服务中取得了卓越的成就。公司取得了15项发明专利、16项实用新型专利、78项软件著作权、2项软件产品登记，自主研发的“基于物联网与灾害数据云的地质灾害监测预警系统”获得了成都市科技进步二等奖、“水文地质及桥隧工程监测预警信息平台关键技术及应用”获得了重庆市科技进步二等奖。
        <br />
        <br />

        目前正发展成为国内领先的智慧水务、智慧交通、物联网应用和互联网服务领域优秀企业，参与了一项国家标准制定、一项行业标准制定、三项地方标准制定。获得“国家级守合同重信用企业”、“国家级高新技术企业”、“四川省企业技术中心”、“成都市企业技术中心”等荣誉称号。公司拥有完备的资质，拥有电子与智能化工程专业承包一级、测绘资质乙级、安防工程企业设计施工维护能力一级等资质、CMMI软件成熟度3级、信息技术服务运行维护标准符合性证书贰级(ITSS)、水文水资源调查评价乙级，通过了ISO9001质量管理体系认证、ISO14001环境管理体系认证、ISO45001职业健康安全管理体系认证、ISO27001信息安全管理体系认证、ISO20000信息技术服务管理体系认证、信用等级AAA证书等。
      </div>
    </div>
    <img class="img" src="../../../assets/imgs/guanyu/jianjie.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.content {
  width: 1920px;
  height: 520px;
  display: flex;

  .img {
    width: 754px;
    height: 520px;
  }
  .jianjie {
    flex: 1;
    padding: 60px 85px 40px 260px;
    background: url("../../../assets/imgs/guanyu/jianjiebg.png");
    background-size: 100% 100%;
    .title {
      font-size: 28px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      line-height: 24px;
      color: #333333;
      //text-align: right;
    }
    .text {
      margin-top: 40px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      line-height: 24px;
      color: #666666;
    }
  }
}
</style>